<template>
 <div>
    <DialogTitle label="Test details" align="center" />
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content label="TEST ID" :text="test.t_id" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content label="Test Name" :text="test.title" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content label="Test Type" :text="test.type" />
      </div>
    </div>
    <div class="md-layout md-gutter mt-6">
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content label="Create Type" :text="test.create_type" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content label="Created By" :text="test.created_by" />
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-small-size-33 md-large-size-33 md-xlarge-size-20">
        <Content label="Total Question" :text="totalQuestion" />
      </div>
    </div>
    <div class="md-layout md-gutter mt-6">
        <div class="md-layout-item md-xsmall-size-100 md-small-size-100 md-large-size-60 md-xlarge-size-75">
            <div class="flex flex-col justify-between outline-gray-400 p-6 h-full rounded">
            <div style="min-height: 450px">
                <div>
                    <h2 class="my-2 text-lg text-uppercase font-semibold">Prompt</h2>
                    <div class="line-height-27 p-2 rounded text-base bg-lite-ash text-justify" v-html="(question) ? question.prompt: ''"></div>
                </div>
                <div>
                    <h2 class="my-2 text-lg text-uppercase font-semibold">Question Index</h2>
                    <div class="line-height-27 text-base text-justify" v-text="(question) ? question.index : ''"></div>
                </div>
                <div>
                    <h2 class="my-2 text-lg text-uppercase font-semibold">Question</h2>
                    <div class="line-height-27 text-base text-justify" v-html="(question) ? question.title: ''"></div>
                </div>
            </div>
            <div class="flex justify-between ">
                <h2 class="opacity-50">
                    {{ currentIndex + 1 }} of {{ totalQuestion }}
                </h2>
                <div class="flex">
                    <div
                        class="p-2 mx-1 pointer outline-gray-400 rounded"
                        @click="prevQuestion"
                        >
                        <md-icon>chevron_left</md-icon>
                    </div>
                    <div 
                        class="p-2 mx-1 pointer outline-gray-400 rounded"
                        @click="nextQuestion"
                        >
                        <md-icon>chevron_right</md-icon>
                    </div>

                </div>
            </div>
            </div>
        </div>
    </div>
    <div class="mt-4">
        <md-button @click="$router.go(-1)" class="bg-victoria text-white m-0 rounded mr-0">Back</md-button>
    </div>
  </div>
</template>

<script>
import { DialogTitle } from "@/components"
import Content from "@/components/atom/Content";
import { mapActions } from "vuex";
export default {
    components: {
        DialogTitle,
        Content
    },
    data() {
        return {
            test: {},
            currentIndex: 0
        }
    },
    computed: {
        totalQuestion() {
            return this.test.questions && this.test.questions.length.toString()
        },
        question() {
            return this.test.questions && this.test.questions[this.currentIndex];
        }
    },
    methods: {
        ...mapActions({
        actTestDetails: "testCreation/actTestDetails",
        }),
        nextQuestion() {
            if(this.currentIndex >= this.totalQuestion - 1) return;
            this.currentIndex++
        },
        prevQuestion() {
            if(this.currentIndex <= 0 ) return;
            this.currentIndex--
        }

    },
  created() {
    this.actTestDetails(this.$route.params.id).then(res => this.test = res);
  },
};
</script>

<style></style>
