<template>
  <div>
    <DialogTitle bg-color="bg-victoria" :label="label" align="center" />
    <form @submit.prevent="onSubmit(1)">

      <div class="md-layout md-gutter mb-4 mt-10">
        <div class="md-layout-item md-size-35">
          <InputFieldComponent
            label="Practice Test Name"
            label-class="text-xl"
            placeholder="SPLECIAL PRACTICE TEST"
            v-model.trim="$v.form.title.$model"
            :message="!$v.form.title.required && $v.form.title.$dirty ? 'Field is required' : null"
          />
        </div>

      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item flex flex-col">
          <label class="text-uppercase font-bold text-xl mb-2 block mt-6">
            Choose Create Type
          </label>
          <div class="flex flex-wrap align-center">
            <RadioButtonComponent
            active-class="font-bold"
            label-class="text-lg text-uppercase"
            :items="[{id: 'RANDOM', label: 'Random'}, {id: 'MANUAL', label: 'Manual'}]"
            v-model.trim="createType"
          />

          <InputFieldComponent
            class="w-24 mx-1"
            placeholder="Quantity"
            v-model.trim="$v.form.quantity.$model"
            :message="!$v.form.quantity.required && $v.form.quantity.$dirty ? 'Field is required' : null"
            v-if="createType == 'RANDOM'"
          />
          </div>
        </div>
        
        <div class="md-layout-item flex flex-col ">
          <label class="text-uppercase font-bold text-xl mb-2 block mt-6">
            Pre Time
          </label>
          <div class="flex flex-wrap">
            <RadioButtonComponent
            active-class="font-bold"
            label-class="text-lg text-uppercase"
            :items="[{id:'DEFAULT', label: 'DEFAULT'}, {id:'CUSTOM', label: 'CUSTOM'}]"
            v-model.trim="form.preparation_time_type"
          />
          <vue-timepicker
            close-on-complete
            v-model="preTime"
            format="mm:ss"
            :disabled="form.preparation_time_type == 'DEFAULT'"
          ></vue-timepicker>
          </div>
        </div>
      </div>
      <AccordionComponent
        :items="questionTypes"
        @clickItem="clickItem"
        v-if="createType == 'MANUAL'" 
      />
     
      <TextAreaComponent
        label="Short Description"
        v-model.trim="form.note"
      />
      <div class="flex justify-between mt-4">
        <md-button class="bg-victoria text-white text-italic rounded ml-0" @click="$router.go(-1)">Back</md-button>

        <div>
          <md-checkbox v-model="createAnother" class="md-secondary">Create another</md-checkbox>
          <md-button class="text-uppercase" @click="onSubmit(0)">Save as draft</md-button>
          <md-button type="submit" class="text-uppercase mr-0 bg-victoria text-white rounded">Publish</md-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { 
  DialogTitle,
  InputFieldComponent,
  TextAreaComponent,
  RadioButtonComponent
  } from "@/components"
import AccordionComponent from "@/components/molecule/AccordionComponent";
import { required } from "vuelidate/lib/validators";
import VueTimepicker from 'vue2-timepicker'
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    RadioButtonComponent,
    VueTimepicker,
    AccordionComponent,
    InputFieldComponent,
    TextAreaComponent,
    DialogTitle
  },
  data() {
    return {
      createAnother: false,
      sections: [
        {id:1, label: 'Reading'},
        {id:2, label: 'Writing'},
        {id:3, label: 'Speaking'},
        {id:4, label: 'Listening'}
      ],
      preTime: '',
      createType: 'RANDOM',
      form: {
        title: "",
        quantity: "",
        note: "",
        instruction: "",
        duration: "",
        section : "",
        create_type: "",
        type : "PRACTICE",
        active:"",
        question_id : []
      },
      label: ''
    };
  },
  computed: {
    ...mapGetters({
      questionTypes: "question/getQuestionTypes",
    }),
    type() {
        return this.$route.query.type
    }
  },
  watch: {
    createType(value) {
      if(value == 'MANUAL') {
        this.actTypeWiseQuestions(`?question_type_id=${this.questionTypes[0].id}`);
      }
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actTypeWiseQuestions: "question/actTypeWiseQuestions",
      actTestCreationSave: "testCreation/actTestCreationSave",
    }),
    onSubmit(active) {
      this.$v.$touch();
      if(this.$v.$invalid) {
        return;
      }

      this.form.create_type = this.createType;
      this.form.duration = this.convertTimeToSecond(this.preTime);
      this.form.active = active;

      this.form.section = this.type.toUpperCase();
      this.actTestCreationSave(this.form)
        .then(response => {
          this.setShowSnackbar(response.message);
          if(!this.createAnother) {
            this.$router.push({name: `dataList.${this.type}`})
          }
        }).catch(error => {
           this.setShowSnackbar(error.response.data.message);
        });
    },
    convertTimeToSecond(time) {
      if(time == '') return 0;
      let [minute, second] = time.split(":");
      return (parseInt(minute)*60) + parseInt(second)
    },
    clickItem(item) {
      this.actTypeWiseQuestions(`?question_type_id=${item.id}`);
    }
  },
  validations() {
    if(this.createType == 'RANDOM') {
      return {
        form: {
          title: {required},
          quantity: {required}
        }
      }
    }else {
      return {
        form: {
          title: {required}
        }
      }
    }
    
  },
  created() {
    this.label = `Create ${this.type} Practice Test`;
    this.actGetQuestionTypes(`?section=${this.type}`);
  }
};
</script>